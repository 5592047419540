import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTelecontrol from './telecontrol.reducer';
import { Telecontrol } from 'src/app/models/Telecontrol';

const { selectAll } = fromTelecontrol.adapter.getSelectors();

export const selectTelecontrolState =
  createFeatureSelector<fromTelecontrol.State>(
    fromTelecontrol.telecontrolFeatureKey,
  );

export const selectTelecontrols = createSelector(
  selectTelecontrolState,
  selectAll,
);

export const selectTelecontrol = (uid: string) =>
  createSelector(
    selectTelecontrols,
    (telecontrols) =>
      telecontrols.find((telecontrol) => telecontrol.Uid === uid) ||
      ({} as Telecontrol),
  );

export const selectTelecontrolsLoading = createSelector(
  selectTelecontrolState,
  (state) => state.loading,
);

export const selectTelecontrolsError = createSelector(
  selectTelecontrolState,
  (state) => state.error,
);

export const selectCombinedTelecontrols = createSelector(
  selectTelecontrols,
  selectTelecontrolsLoading,
  selectTelecontrolsError,
  (telecontrols, loading, error) => ({
    telecontrols,
    loading,
    error,
  }),
);

export const selectCombinedTelecontrol = (uid: string) =>
  createSelector(
    selectTelecontrol(uid),
    selectTelecontrolsLoading,
    selectTelecontrolsError,
    (telecontrol, loading, error) => ({
      telecontrol,
      loading,
      error,
    }),
  );
